import PropTypes from "prop-types"
import React, {useState} from "react"
import {makeStyles} from '@material-ui/core/styles';
import AppBar from "@material-ui/core/AppBar";
import Hamburger from "hamburger-react";

const Header = ({siteTitle, switchFlag}) => {
    const useStyles = makeStyles((theme) => ({
        appBar: {
            // borderBottom: `1px solid ${theme.palette.divider}`,
            backgroundColor: 'rgba(110,35,55,0)',
            // position:'fixed'
        },
        title: {
            fontFamily: 'Helvetica Neue, sans-serif, serif',
            fontSize: '2.2vw',
            fontWeight: 'bold',
            color: 'seashell',
            textShadow: '1px 1px 150px #080705',
            textAlign: 'center',
            display: 'block',
            [theme.breakpoints.down('md')]: {
                fontSize: '5vw',
                margin: '0.3rem 0 0 0 ',
            }
        },
        hamburger:{
            // float:'right !important',
            margin: 0,
            top: 'auto',
            right: 20,
            bottom: 'auto',
            left: 'auto',
            position: 'fixed',
            zIndex:2147483647,
            color:"#DA8E00",
            [theme.breakpoints.down('md')]: {
                top:1,
                right:5
            }

        },
    }));

    const classes = useStyles();
    const [isOpen, setOpen] = useState(false);

    return (
        <AppBar position="static" elevation={0} className={classes.appBar}>
            <div className={classes.hamburger} onClick={switchFlag(isOpen)}>
                <Hamburger  toggled={isOpen} easing="ease-in" duration={0.45} label="Show menu" toggle={setOpen} />
            </div>
        </AppBar>
    )
};

Header.propTypes = {
    siteTitle: PropTypes.string,
}

Header.defaultProps = {
    siteTitle: ``,
};

export default Header;

import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {graphql, Link, useStaticQuery} from "gatsby"
import Container from "@material-ui/core/Container";
import Slide from "@material-ui/core/Slide";
import {Box} from "@material-ui/core";
import Img from "gatsby-image";
import useMediaQuery from '@material-ui/core/useMediaQuery';


const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(0, 0, 0)
    },

    overRay: {
        position: 'fixed',
        left: 0,
        top: 0,
        width: '100%',
        height: '100vh',
        display: 'flex',
        // zIndex: 2147483646,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            flexDirection: 'column',
        },
    },
    overRayHidden: {
        display: 'none'
    },
    overRayGridMenu1: {
        background: '#1d2027',
        width: '100%',
        height: '100%',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },

    },
    overRayGridMenu2: {
        background: '#1d2027',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: '20vh',
        },
    },
    overRayGridMenu3: {
        background: '#15171b',
        width: '45%',
        height: '100%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: '25vh',
            margin: '1rem 0 1rem 0 ',
        },
    },
    profile: {
        height: 'auto',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
    },
    profile2: {
        height: 'fit-content',
        width: '100%',
        [theme.breakpoints.down('md')]: {
            height: '50vh',
            width: '100%',
        },
        [theme.breakpoints.down('sm')]: {
            // height: '50v',
            height: '53vh',
            width: '100%',
        },


    },

    menu: {
        fontFamily: 'Helvetica Neue, sans-serif, serif',
        textDecoration: 'none',
        color: '#feffff',
        margin: '1rem 0 0 0;',
        whiteSpace: 'nowrap',
        fontSize: '3rem',
        width: '100%',
        textAlign: 'center !important',
        padding: '1rem',
        // height: 'calc(100% - 3rem)',
        '&:hover': {
            color: 'goldenrod',
            '&::after': {
                // width: '100%'
                content: '27F6',
                display: 'block',
                marginTop: 'auto',
                fontSize: '1.2rem',
            }
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.75rem',
            padding: '0.6rem',
            margin: '0.5rem',
        },
    },
    menuList: {
        letterSpacing: '0.15rem',
        lineHeight: '6rem',
        marginTop: "2rem",
        width: '100%',
        listStyle: 'none',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',

        [theme.breakpoints.down('sm')]: {
            flexDirection: 'row',
            lineHeight: '6.3vh',
            flexWrap: 'wrap',
            padding: '0.5vw',
            marginTop: '2.75vh',

        },


    },

    snsMenu: {
        letterSpacing: '0.15rem',
        lineHeight: '2rem',
        width: '100%',
        listStyle: 'none',
        display: 'flex',
        // flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '0rem 0rem 0rem 0rem',
        padding: '3rem',
        borderTop:'1px solid #feffff',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignItems: 'center',
            margin: '0rem 3rem 10rem 0rem',
        },

    },

    sideMenu: {
        letterSpacing: '0.15rem',
        lineHeight: '3rem',
        width: '40%',
        listStyle: 'none',
        display: 'flex',
        // flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '1rem 2rem 1rem 0rem',

        [theme.breakpoints.down('sm')]: {
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignItems: 'center',
            margin: '0',
        },

    },
    sideMenuLink: {
        textDecoration: 'none',
        '&:hover': {
            color: 'goldenrod',
            '&::after': {
                // width: '100%'
                content: '27F6',
                display: 'block',
                marginTop: 'auto',
                fontSize: '1.2rem',
            }
        },
        [theme.breakpoints.down('sm')]: {
            margin: '2.8vw',
        }
    },
    spanSideMenu: {
        fontFamily: 'Helvetica Neue, sans-serif, serif',
        fontSize: '1.2rem',
        color: '#f2f2f2',
        '&:hover': {
            color: 'goldenrod',
            '&::after': {
                // width: '100%'
                content: '27F6',
                display: 'block',
                marginTop: 'auto',
                fontSize: '1.2rem',
            }
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '4.2vw',
        },
    },
    sideMenuList: {
        float: 'left',
        [theme.breakpoints.down('sm')]: {
            margin: '0rem'
        }
    }
}));

export default function DrawerMenu({isOpen}) {
    const classes = useStyles();
    const matches = useMediaQuery(theme => theme.breakpoints.up('md'));
    const data = useStaticQuery(graphql`
        query{
            placeholderImage: file(relativePath: {eq: "menu_01.jpg"}) {
                childImageSharp {
                    fluid(quality: 15, webpQuality: 50) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            },
            image1: file(relativePath: {eq: "menu_01.jpg"}) {
                childImageSharp {
                    fluid(quality: 15, maxWidth: 1200) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            },
            image2: file(relativePath: {eq: "memories_menu.png"}) {
                childImageSharp {
                    fluid(quality: 65, maxWidth: 1200, fit: COVER) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }`
    );

    const list = (
        <ul className={classes.menuList}>
            <li>
                <Link className={classes.menu} to="/">Home</Link>
            </li>
            <li>
                <Link className={classes.menu} to="/topics">Topics</Link>
            </li>
            <li>
                <Link className={classes.menu} to="/schedule">Schedule</Link>
            </li>
            <li>
                <Link className={classes.menu} to="/biography">Biography</Link>
            </li>
            <li>
                <Link className={classes.menu} to="/discography">Discography</Link>
            </li>

            <li>
                <Link className={classes.menu} to="/contact">Contact</Link>
            </li>
        </ul>);

    return (
        <Container maxWidth="lg" component="main" className={classes.root}>
            <div className={classes.overRay} style={{zIndex: -5}}>
                <Slide direction={matches ? "up" : "left"} in={isOpen} mountOnEnter unmountOnExit
                       timeout={{enter: 400, exit: 500}}>
                    <Box
                        display="flex"
                        flexDirection="column"
                        flexWrap="wrap"
                        order={matches ? 1 : 0}
                        className={classes.overRayGridMenu3}>

                        <Box alignItems="center">
                            {list}
                        </Box>
                        {matches ?
                        <Box alignItems="center">
                        <ul className={classes.snsMenu}>

                            <li className={classes.sideMenuList}>
                                <a href="https://linktr.ee/aoimichelle" title="SNS"
                                   className={classes.menu}>
                                    <span className={classes.spanSideMenu}>SNS/ Shop / 速報スケジュール/ 各種ページはこちら</span>
                                </a>
                            </li>
                        </ul>
                        </Box> : null }
                    </Box>
                </Slide>
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    flexGrow={1}>

                    <Slide direction={matches ? "down" : "right"} in={isOpen} mountOnEnter unmountOnExit
                           timeout={{enter: 500, exit: 500}}>
                        <Box order={0} display="flex" alignContent="center" className={classes.overRayGridMenu1}>
                            <Img
                                alt={data.image2.name}
                                fluid={data.image2.childImageSharp.fluid}
                                className={classes.profile2}
                                objectFit="cover"
                                objectPosition="100% 10%"
                            />
                        </Box>
                    </Slide>
                    {!matches ?
                    <Slide direction={matches ? "right" : "up"} in={isOpen} mountOnEnter unmountOnExit
                           timeout={{enter: 500, exit: 500}}>
                        <Box p={0} className={classes.overRayGridMenu1}>
                            <div className={classes.overRayGridMenu2}>
                                <ul className={classes.sideMenu}>

                                    <li className={classes.sideMenuList}>
                                        <a href="https://linktr.ee/aoimichelle" title="SNS"
                                           className={classes.menu}>
                                            <span className={classes.menu}>SNS/ Shop / 速報スケジュール/ 各種ページはこちら</span>
                                        </a>
                                    </li>
                                </ul>
                                {/*{matches ? (*/}
                                {/*    <Img*/}
                                {/*        alt={data.image1.name}*/}
                                {/*        fluid={data.image1.childImageSharp.fluid}*/}
                                {/*        className={classes.profile2}*/}
                                {/*        objectFit="cover"*/}
                                {/*        objectPosition="100% 10%"*/}
                                {/*    />) : null}*/}
                            </div>
                        </Box>
                    </Slide> : null }
                </Box>
            </div>
        </Container>
    );
}

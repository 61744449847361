/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import ImageFader from "./image-fader";
import Fade from "@material-ui/core/Fade";

export default function Main ({ children, bgImage,loading}) {

    const images = useStaticQuery(graphql`
        query SiteTitleQuery {
            allImages: allFile(
                sort: { fields: name }
                filter: { sourceInstanceName: { eq: "startImages" } }
            ) {
                edges {
                    node {
                        childImageSharp {
                            fluid(quality: 100, maxWidth: 1200, fit: CONTAIN) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                }
            }
        }
    `);
    const { allImages } = images;
    return (
        <>
            {bgImage ? (<ImageFader images={allImages.edges} />) :null}
            <Fade in={!loading} timeout={{enter:500, exit:500}}>
            <main >{children}</main>
            </Fade>
        </>
    )
}

Main.propTypes = {
    children: PropTypes.node.isRequired,
}

/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import {makeStyles} from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import {MuiThemeProvider, createMuiTheme} from "@material-ui/core/styles";
import Main from "./main";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Header from "./header";
import "./layout.css"
import DrawerMenu from "./drawerMenu";
export default function Layout({children, siteTitle, bgImage}) {
    const theme = createMuiTheme({
        palette: {
            background: {
                default: '#15171b',
            },
        },
        typography: {
            fontFamily: [
                'Helvetica Neue sans-serif, serif',
            ].join(','),
        },

    });

    const useStyles = makeStyles((theme) => ({
        button: {
            margin: 0,
            top: 'auto',
            right: 80,
            bottom: 'auto',
            left: 'auto',
            position: 'fixed',
            color: "#f2f2f2"
        },
        bgImage: {
            position: 'fixed',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
        },
        loading: {
            position: 'absolute',  /*要素を浮かす*/
            /*relativeを指定した親要素を支点とした位置をすべて0に指定↓*/
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            margin: 'auto', /*上下左右中央に*/
            color: "#f2f2f2"
        },
        loadText: {
            fontFamily: 'Helvetica Neue sans-serif, serif',
            lineHeight: 0.8,
            fontWeight: 'bold',
            color: 'seashell',
            textShadow: '1px 1px 2px #080705',
            fontSize: '6vw',
            // webkitAnimation: '$text-focus-in 1s cubic-bezier(0.550, 0.085, 0.680, 0.530) both',
            [theme.breakpoints.down('sm')]: {
                fontSize: '10vw',
            },
            animation: '$text-focus-in 1.2s cubic-bezier(0, 0, 0.10, 0.330) both',
            '@global': {
                '@keyframes text-focus-in': {
                    '0%' :{
                    filter: 'blur(12px)',
                    opacity: 0
                    },
                    '100%' :{
                        filter: 'blur(0px)',
                        opacity: 1
                    }
                },
                '@keyframes loaderAnim': {
                    'to':{
                        opacity: 1,
                        transform: 'scale3d(0.5, 0.5, 1)'
                    }
                }
            },
        }
    }));
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setTimeout(() => setLoading(false), 1000)
    }, []);

    const classes = useStyles();
    const [isOpened, setOpened] = useState(false);
    return (
        <MuiThemeProvider theme={theme}>
            <CssBaseline/>

            {loading === false ? (
                <>
                    <Header siteTitle={siteTitle} switchFlag={setOpened}/>
                    {/*<TemporaryDrawer　switchFlag={setOpened} />*/}
                    <DrawerMenu isOpen={isOpened}/>
                    {!isOpened ?(<Main children={children} bgImage={bgImage} loading={loading}/>):null}
                </>
            ) : (
                <div className={classes.loading}>
                    <Box
                        top={0}
                        left={0}
                        bottom={0}
                        right={0}
                        position="absolute"
                        display="flex"
                        alignItems="center"
                        justifyContent="center">
                        <Typography variant="caption" component="div" className={classes.loadText}>
                            Aoi MichellE
                        </Typography>
                    </Box>
                </div>
            )}
        </MuiThemeProvider>

    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}
